<template>
  <v-list
    style="max-height:80vh; overflow: auto;"
    two-line
    dense
    color="transparent"
    class="pr-1 pa-0 ma-0"
  >
    <v-list-item class="item-list-delivery">
      <v-avatar class="mr-3" color="white">
        <v-icon :color="$theme.secondary">mdi-map-marker-path</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title
          :style="`color: ${$theme.secondary}`"
          class="fonte font-weight-bold"
        >
          Nova área personalizada
        </v-list-item-title>
        <v-list-item-subtitle class="fonte">
          <span class="fonteMini grey--text">
            Clique no mapa para criar
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item v-if="!default_address_store.formatted_address" @click="openModalFindStore" class="item-list-delivery"> -->
    <v-list-item @click="openModalFindStore" class="item-list-delivery">
      <v-avatar class="mr-3" color="white">
        <v-icon :color="$theme.primary">mdi-cog</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title
          :style="`color: ${$theme.primary}`"
          class="fonte font-weight-bold"
        >
          Área por kilometro
        </v-list-item-title>
        <v-list-item-subtitle class="fonte">
          <span class="fonteMini grey--text">
            clique para configurar
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="openModalCreateAreaByKm"
      v-if="default_address_store"
      class="item-list-delivery"
    >
      <v-avatar class="mr-3" color="white">
        <v-icon :color="$theme.secondary">mdi-map-marker</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title
          :style="`color: ${$theme.secondary}`"
          class="fonte font-weight-bold"
        >
          Nova área por km
        </v-list-item-title>
        <v-list-item-subtitle class="fonte">
          <span class="fonteMini grey--text">
            Clique aqui para cadastrar
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="fonte pa-0 ma-0 font-weight-bold">
          Áreas Cadastradas
        </v-list-item-title>
        <v-list-item-subtitle class="fonte">
          Veja abaixo as áreas cadastradas
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-for="(area, index) in areas"
      :key="area.id"
      class="item-list-delivery"
      @click="selectFeature(area)"
    >
      <!-- <v-avatar class="mr-3" color="white">
        <v-icon :color="$theme.secondary">mdi-map-marker-path</v-icon>
      </v-avatar> -->
      <v-list-item-content>
        <v-list-item-title class="fonte-bold white--text font-weight-bold">
          {{ area.description }}
        </v-list-item-title>
        <v-list-item-subtitle class="fonte white--text">
          {{ $helper.formataSaldo(area.value || 0) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="fonte grey--text">
          {{ area.time_delivery }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="area.area_type === 'by_km'" class="fonte">
          <v-chip dark small :color="$theme.secondary">
            De {{ area.init_km }} à {{ area.end_km }} KM
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <!-- <icon name="pro/Animal/bat" :size="30" :color="$theme.primary" /> -->
        <v-btn
          v-if="!area.area_type || area.area_type === 'by_area'"
          @click="selectFeature(area)"
          small
          icon
        >
          <v-icon size="14" :color="$theme.primary">
            mdi-target
          </v-icon>
        </v-btn>
        <v-btn @click="confirmRemoveArea(index)" small icon>
          <v-icon size="14" color="grey">
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      lat: "",
      long: ""
    };
  },
  props: [
    "areas",
    "selectFeature",
    "updateAreas",
    "confirmRemoveArea",
    "openModalCreateAreaByKm",
    "openModalFindStore",
    "default_address_store"
  ],
  methods: {
    saveLatLong() {
      return;
    }
  }
};
</script>

<style scoped>
.item-list-delivery {
  background: #474747;
  margin-bottom: 12px;
  border-radius: 6px;
  color: #f2f2f2;
}
</style>
